import React from "react";
import {
  Box,
  Stack,
  Text,
  OrderedList,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import Container from "components/ui/Container";
import Seo from "components/Seo";
import { BsFillDiamondFill } from "react-icons/bs";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box py={10}>
      <Seo
        title="Terms & Conditions"
        description="Terms & conditions"
        keywords="interior design software, online house planner, online floor plan, home design software, floor planner software,  3D home design software, 3D house plan software, online interior design software, home remodeling software, online rendering software, 3D visualization tool, get design ideas, design gallery, brand gallery."
      />

      <Container fluid>
        <Stack spacing={6} color="gray.600">
          <Text
            textAlign="center"
            fontSize="4xl"
            textDecor="underline"
            color="black"
          >
            Terms & Conditions
          </Text>

          <Text fontSize="md">
            Special tips:
            <br />
            Please read carefully and fully understand the terms and conditions
            of this agreement before using services provided by 3Vjia,
            especially those marked in bold, including but not limited to
            exemption clause or limitation clause.{" "}
            <Text as="span" color="orange">
              If you don't agree to this agreement, and/or the modification at
              any moment, you may initiative stop using the services provided by
              3Vjia. Once you use the services provided by 3Vjia, you will be
              deemed to have understood and fully agreed to all contents of this
              agreement including any modification 3Vjia may make to this
              agreement from time to time, and become our user.
            </Text>
          </Text>

          <OrderedList
            stylePosition="inside"
            styleType="upper-roman"
            type="I"
            fontSize="xl"
          >
            <ListItem>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Scope of service and agreement
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    3Vjia (Guangdong 3Vjia information technology Co., Ltd.) is
                    the platform owner, service provider and operator of
                    aihouse. "Aihouse platform" refers to client of website
                    (aihouse.com), mobile phone, computer and other devices;
                    "User" refers to a natural person, legal person or other
                    organization (hereinafter uniformly referred to as "you")
                    with full capacity for civil conduct and full capacity for
                    civil liability who understands and accepts all terms of
                    this agreement, completes the registration process on
                    aihouse platform or uses the 3Vjia service.
                  </ListItem>
                  <ListItem mt={3}>
                    When you use 3Vjia for a particular service, the service may
                    have a separate agreement, relevant business rules, and so
                    on. The above content will be published and displayed in the
                    form of website announcement, notice, electronic document,
                    website letter, help center document and other forms. Once
                    officially released, it will be regarded as an integral part
                    of this agreement and you shall also abide by it. Your
                    acceptance of any of the foregoing separate agreements and
                    business rules shall be deemed to be your entire acceptance
                    of this agreement.
                  </ListItem>
                  <ListItem mt={3}>
                    After you sign this agreement, the content of agreement may
                    be amended due to the changes in national policies, products
                    and performing environment. Amended agreement will be issued
                    in this website. If you have any objection to the amended
                    agreement, please immediately stop logging in or stop using
                    3Vjia service; login or continue to use 3Vjia service will
                    be deemed to accept amended agreement.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              User registration and real-name authentication
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha" mt={3}>
                  <ListItem>
                    In order to use aihouse platform service, you shall register
                    an aihouse account by using your email address according to
                    3Vjia requirement. You can login aihouse platform through
                    aihouse user account or the third-party website account
                    (Facebook, Google, etc.); you can also login as anonymous
                    user to enjoy using some of functions or services such as
                    browsing and information access. In any case, you should
                    abide by the terms and conditions of platform agreement and
                    business rules.
                  </ListItem>
                  <ListItem>
                    You shall comply with relevant laws and regulations for
                    real-name authentication. For some of 3Vjia services
                    opening, you may required to provide further identity
                    information, business qualifications and information to
                    complete identity authentication or qualification
                    verification. You may order and use services only after
                    completing the corresponding identity authentication and
                    qualification verification. You understand and agree that
                    3Vjia has the right to review whether the information
                    provided by you in real-name authentication is true,
                    accurate and valid by itself or entrust with a third party.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Account security and account cancellation
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    After registration is successful, 3Vjia will give each user
                    an account and the password set by user. You are responsible
                    for keeping this user account and password, and you shall be
                    legally responsible for all activities and events carried
                    out by this user account.
                  </ListItem>
                  <ListItem>
                    You should keeping account information properly, including
                    account number, password, verification code and other
                    information. The user account is limited to registered user
                    only, do not allowed to be transferred, traded, given,
                    rented or borrowed. If the user is not initial registrant of
                    the account, 3Vjia has the right to recover account without
                    notice, and does not need to assume legal liability to the
                    user of this account. The consequent losses including but
                    not limited to interruption of user communication, user
                    data, content, service, virtual currency and other emptying
                    losses, shall be borne by the user itself.
                  </ListItem>
                  <ListItem>
                    You understand and agree that, 3Vjia does not assume any
                    responsibility and obligation to the flowing situation: (1)
                    If you have not login on aihouse platform website for 12
                    consecutive months and your account is not in arrears, no
                    3Vjia related service have been opened or the service has
                    not been renewed, also there's no balance in your account.
                    (2) You are in violation of laws and regulations, provisions
                    of this agreement or provisions on administration of 3Vjia.
                    Or (3) as required by relevant laws and regulations, 3Vjia
                    has the right to cancel your account and terminate the
                    service, and you will not be able to login aihouse platform
                    website or use the service.
                  </ListItem>
                  <ListItem>
                    You also have the right to terminate user account. When you
                    decide not to use the user account, first you should confirm
                    that there is no unexpired task under your user account. At
                    the same time, you should apply to 3Vjia for cancellation of
                    the user account after you have paid all the accounts
                    payable (including but not limited to the principal,
                    interest, liquidated damages, service fees, etc.) , and the
                    user account will be formally cancelled after approval by
                    3Vjia.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Service fee and other fees
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    Some service provided by 3Vjia will be charged a certain
                    service fee, please refer to service market and the service
                    agreement signed by both parties for details. 3Vjia may
                    modify and change the charge standards and methods of
                    charging services according to actual needs. Before the
                    aforementioned modification, change or start charging, 3Vjia
                    will be separately agreed in the service market or in the
                    form of agreement.
                  </ListItem>
                  <ListItem>
                    The fees incurred by both parties for performance of this
                    agreement, including but not limited to bank service fee or
                    other fees related to payment, shall be borne by both
                    parties respectively. Please refer to relevant pages, tips
                    and charging standards of third-party websites for specific
                    charge standards.
                  </ListItem>
                  <ListItem>
                    If you violate the fee and payment agreement agreed by the
                    aihouse platform or agreed upon in writing by both parties,
                    you shall assume the corresponding responsibilities and
                    obligations in accordance with the relevant policies and
                    regulations on the aihouse platform or agreement between the
                    two parties. If you delay fulfilling your payment
                    obligations, you should pay 0.5% of the unpaid amount due
                    and payable to 3Vjia as liquidated damages for every one day
                    overdue. 3Vjia shall have the right not to provide relevant
                    cloud services and shall not bear any liability until you
                    fully perform the corresponding payment obligation.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Obligations and guarantees of compliance with the law
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpga">
                  <ListItem>
                    When using 3Vjia service, you must abide by the relevant
                    laws and regulations of the People's Republic of China, such
                    as the Network Security Law and the Regulations on the
                    Administration of Internet News Information Services. You
                    should agree that you will not use the services for any
                    illegal or improper activities, including but not limited to
                    the following acts:
                    <OrderedList stylePosition="inside">
                      <ListItem>
                        Upload, display, disseminate or otherwise transmit
                        information containing one of the following:
                        <OrderedList
                          stylePosition="inside"
                          type="a"
                          styleType="lower-alpha"
                        >
                          <ListItem>
                            Opposing the basic principles set forth in the
                            constitution;
                          </ListItem>
                          <ListItem>
                            Endangering national security, divulging state
                            secrets, subverting state power and undermining
                            national unity;
                          </ListItem>
                          <ListItem>
                            Damaging the honor and interests of the state;
                          </ListItem>
                          <ListItem>
                            Inciting national hatred, ethnic discrimination or
                            undermining national unity;
                          </ListItem>
                          <ListItem>
                            Damaging the state's religious policies and
                            promoting cults and feudal superstitions;
                          </ListItem>
                          <ListItem>
                            Spreading rumors, disturbing social order and
                            destabilizing society;
                          </ListItem>
                          <ListItem>
                            Disseminating obscenity, pornography, gambling,
                            violence, murder, terror, or abetting crime;
                          </ListItem>
                          <ListItem>
                            Insulting or slandering others and infringing upon
                            others legitimate rights;
                          </ListItem>
                          <ListItem>
                            Containing false, harmful, threatening, infringing
                            on the privacy of others, harassment, infringement,
                            slander, vulgarity, indecency, or other morally
                            objectionable content;
                          </ListItem>
                          <ListItem>
                            Containing other contents restricted or prohibited
                            by Chinese laws, regulations, rules and ordinances,
                            and any norms with legal effect.
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                      <ListItem>
                        Do not use the network service systems for any illegal
                        purpose;
                      </ListItem>
                      <ListItem>
                        Do not use the network service systems for any illegal
                        purpose;
                        <OrderedList
                          stylePosition="inside"
                          type="a"
                          styleType="lower-alpha"
                        >
                          <ListItem>
                            Access to computer information network or use of
                            computer information network resources without
                            permission;
                          </ListItem>
                          <ListItem>
                            Delete, modify or add functions of computer
                            information network without permission;
                          </ListItem>
                          <ListItem>
                            To delete, modify or add data and applications
                            stored, processed or transmitted in the computer
                            information network without permission;
                          </ListItem>
                          <ListItem>
                            Intentionally creating or disseminating destructive
                            programs such as computer viruses;
                          </ListItem>
                          <ListItem>
                            Other behaviors that endanger security of computer
                            information network.
                          </ListItem>
                        </OrderedList>
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                  <ListItem>
                    Including reasonable attorney's fee, any claim, demand or
                    loss claimed by any third party, resulting from or arising
                    from your violation of this Agreement or related terms of
                    service, you agree to indemnify and hold harmless 3Vjia from
                    damage. In this regard, according to the nature of user
                    behavior, 3Vjia has the right to take measures including but
                    not limited to deleting the user's published information,
                    suspending the usage license, terminating service,
                    restricting the use, recovering user account, investigating
                    legal liabilities and so on. For the users who maliciously
                    register user account or use user account for illegal
                    activities, disturbances, harassment, deception, other users
                    and other violations of this agreement, 3Vjia has the right
                    to recover the user account.
                  </ListItem>
                  <ListItem>
                    You shall be legally responsible for your behavior in using
                    3Vjia service. The forms of legal liability borne by users
                    include but not limited to: compensation for the infringed;
                    and after 3Vjia undertakes the administrative penalty or
                    tort damage compensation liability caused the user's
                    behavior, the user should give 3Vjia equal amount
                    compensation.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Service provision, suspension and termination
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>serviceAgreement.question6.point1</ListItem>
                  <ListItem>
                    If this agreement or this service terminates for any reason
                    (including but not limited to force majeure, user arrears,
                    user irregularities, etc.), for all data in your 3Vjia
                    account or any information stored in 3Vjia server due to the
                    use of 3Vjia service, 3Vjia will keep it for you for 60
                    days. You should complete all data migration and backup in
                    time. After the expiration of reservation period, 3Vjia will
                    terminate the service, and all your date will be
                    automatically deleted by service system.
                    <OrderedList stylePosition="inside">
                      <ListItem>
                        Incidents caused by hackers, viruses, technical
                        adjustment of telecommunication departments and
                        interruption of backbone lines;
                      </ListItem>
                      <ListItem>
                        The 3Vjia needs to interrupt service during network
                        adjustment and maintenance, or you temporarily cannot
                        use 3Vjia service due to the blocking of internet
                        access;
                      </ListItem>
                      <ListItem>
                        Routine maintenance provided by 3Vjia, including but not
                        limited to fault handling, system upgrade, system
                        tuning, system capacity expansion, etc., which results
                        in the unavailability of 3Vjia service and the
                        unavailability of your content;
                      </ListItem>
                      <ListItem>
                        User's improper operation or failure of user's computer
                        software, system, hardware and communication line;
                      </ListItem>
                      <ListItem>
                        3Vjia cannot continue to provide services for you due to
                        the adjustment of national or operator policies.
                      </ListItem>
                      <ListItem>
                        Other not 3Vjia's fault, the situation that 3Vjia cannot
                        control or reasonably foresee.However, 3Vjia will inform
                        you in advance as far as possible, so that you can do a
                        good job of relevant data migration and backup, business
                        adjustment, etc., in order to protect your legitimate
                        rights and interests. If the change, interruption or
                        termination of network service belongs to the free
                        network service, the 3Vjia service does not need to
                        inform users, also does not need to bear any
                        responsibility to any user or any third party.
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                  <ListItem>
                    You understand and agree that the suspension, interruption
                    or termination of user services and/or user accounts does
                    not represent the termination of user liability. The user
                    shall still be liable for possible breach of contract or
                    damages caused by his/her behavior during his/her use of the
                    3Vjia service, and 3Vjia can still retain the relevant
                    information of user.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Liability exemption
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    3Vjia reserves the right to change, upgrade, modify and
                    migrate the aihouse platform, its related functions and
                    application software by itself. 3Vjia further reserves the
                    right to develop new modules, functions, software and
                    service in aihouse platform. Provision of all new modules,
                    functions, software and services mentioned above shall
                    remain apply to this agreement unless otherwise specified by
                    3Vjia.
                  </ListItem>
                  <ListItem>
                    You agree that without gross negligence or malice, 3Vjia
                    shall not be liable for any delay, inaccuracy, error,
                    breakpoint, omission and consequent damages in data
                    transmission when you or any third party using aihouse
                    platform service.
                  </ListItem>
                  <ListItem>
                    In any case, the third party shall be responsible for
                    providing the relevant services during your use of aihouse
                    platform services, and 3Vjia is not responsible for such
                    responsibilities.
                  </ListItem>
                  <ListItem>
                    3Vjia does not provide any warranty or conditions for any
                    user and/or any transaction, whether express or implied.
                    3Vjia cannot and will not attempt to control the information
                    released by users. 3Vjia does not undertake any form of
                    certification and identification services for such
                    information. 3Vjia cannot fully guarantee the authenticity,
                    adequacy, reliability, accuracy, integrity and validity of
                    the platform content, and does not need to bear any legal
                    responsibility arising therefrom. Before making trading
                    decisions, users should fully understand the relevant
                    transactions and make prudent decisions according to their
                    trading objectives, risk tolerance and asset status, also
                    take all risks by themselves.
                  </ListItem>
                  <ListItem>
                    Except as otherwise mandatory provisions in law, all
                    compensation liability of 3Vjia to you under this agreement
                    and related agreements shall be limited to the direct actual
                    loss proved by clear evidence. All compensation for losses
                    and liquidated damages based on this agreement and related
                    agreements should not exceed accumulated total of 3Vjia
                    products or services that caused your losses and have been
                    paid, and 3Vjia can provide equivalent services as
                    compensation. If service period exceeds 12 months, maximum
                    amount shall not exceed the amount of 3Vjia service that
                    caused your loss and part of service fee has been paid to
                    3Vjia in that year, 3Vjia can provide equivalent services as
                    compensation. The company shall not be liable for any
                    indirect, punitive, special or consequential loss (including
                    loss of business, loss of earnings, loss of profits, loss of
                    date used or loss of other economic interests) related to or
                    caused by this Agreement.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Privacy policy
              <Box fontSize="md">
                3Vjia will take reasonable and appropriate security measures to
                help you protect your personal information and related content
                from accidental or illegal loss, access or disclosure. For this
                reason, 3Vjia have specially formulated the Privacy Policy as an
                integral part of this agreement, which has the same legal effect
                as this agreement. Please read and understand the relevant
                provisions of Privacy Policy carefully. Where there are
                differences between the provisions of this agreement and Privacy
                Policy, or there is no explicit agreement, the content of
                Privacy Policy shall prevail.
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Intellectual property rights
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    All content on aihouse platform, including but not limited
                    to works, pictures, information, materials, platform
                    pictures arrangement and web page design, are owned by 3Vjia
                    or other rights holders according to law, including but not
                    limited to trademark rights, patent rights, copyright, trade
                    secrets, etc. Without the written authorization of 3Vjia or
                    other rights holder, no one may use, modify, duplicate,
                    reprint, change, distribute, issue or publish the content of
                    aihouse platform.
                  </ListItem>
                  <ListItem>
                    Without the explicit written consent of 3Vjia, the 3Vjia
                    website or any part thereof shall not be reproduced, copied,
                    counterfeited, sold, resold, visited or otherwise utilized
                    for any commercial purpose.
                  </ListItem>
                  <ListItem>
                    All rights of any software (including but not limited to any
                    images, photos, animations, videos, recordings, music, text
                    and additional procedures, accompany with supporting
                    materials) used by 3Vjia to provide network services belong
                    to the copyright owner of the software. Without permission
                    of the software copyright owner, users may not reverse
                    engineer, decompile or disassemble, or otherwise discover
                    its original code to the software, and carry out any acts
                    suspected of infringing copyright.
                  </ListItem>
                  <ListItem>
                    Aihouse platform logo, "3Vjia", "aihouse", "aihouse.com",
                    such as text, graphics and their combination, as well as
                    other marks, symbols, products and service names of aihouse
                    platform are the trademarks of the company in China or other
                    countries. Without written authorization, no one can
                    display, use or other processing in any way, or show others
                    that you have right to display, use or other processing.
                  </ListItem>
                  <ListItem>
                    If you publish any content on the aihouse platform, it means
                    that you agree to grant free, irrevocable, permanent,
                    re-licensed or transferable non-exclusive license to the
                    3Vjia on a global scale, for any form of content you publish
                    on the site at any time. According to these permissions,
                    3Vjia has the right to display, promote and use the above
                    contents and works in other ways that are not prohibited by
                    Chinese law; at the same time, 3Vjia has the right to add
                    relevant watermarks or marks of 3Vjia to the content and
                    works. Of course, this does not mean that you give up
                    ownership of content; you simply grant the platform and
                    other user access to use the content.
                  </ListItem>
                  <ListItem>
                    When using the content uploaded by other users, you should
                    note that your authorization to use content uploaded by
                    other users is limited to the use for personal and
                    non-commercial purposes. For example, using models uploaded
                    by other users to model libraries for interior decoration
                    design and generate design schemes. You may not copy,
                    reproduce or otherwise use such user content without the
                    authorization of the 3Vjia and original author. Otherwise,
                    you need to bear the corresponding tort liability.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Dispute Resolution and Law Application
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    The conclusion, validity, interpretation, performance and
                    dispute resolution of this agreement shall be governed only
                    by laws and regulations of the People's Republic of China.
                  </ListItem>
                  <ListItem>
                    Any dispute concerning this Agreement shall be settled by
                    both parties through friendly negotiations in good faith. If
                    negotiation fails, both parties agree to submit the dispute
                    to Hong Kong International Arbitration Centre for
                    settlement.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
          </OrderedList>

          <Text color="gray.400" fontSize="md">
            This agreement is amended on January 2, 2019
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;
